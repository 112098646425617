var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _c(
      "div",
      { staticClass: "box has-padding-large brand_color" },
      [
        _c(
          "h5",
          {
            staticClass:
              "title is-5 has-text-black has-text-centered has-margin-bottom-medium"
          },
          [
            _c(
              "figure",
              {
                staticClass: "image logo is-clickable",
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "login" })
                  }
                }
              },
              [_c("img", { attrs: { src: "/images/logo.png" } })]
            )
          ]
        ),
        _c(
          "form",
          {
            staticClass: "form has-margin-bottom-medium",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submit()
              }
            }
          },
          [
            _c("div", { staticClass: "field" }, [
              _c(
                "div",
                { staticClass: "control has-icons-left has-icons-right" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email"
                      },
                      { name: "focus", rawName: "v-focus" }
                    ],
                    staticClass: "input",
                    class: {
                      "is-danger": _vm.errors.has("email"),
                      "is-success": _vm.isSuccessful
                    },
                    attrs: {
                      type: "email",
                      placeholder: _vm.i18n("Email"),
                      autocomplete: "email"
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        },
                        function($event) {
                          return _vm.errors.clear("email")
                        }
                      ]
                    }
                  }),
                  _c(
                    "span",
                    { staticClass: "icon is-small is-left" },
                    [_c("fa", { attrs: { icon: "envelope" } })],
                    1
                  ),
                  _vm.isSuccessful
                    ? _c(
                        "span",
                        {
                          staticClass: "icon is-small is-right has-text-success"
                        },
                        [_c("fa", { attrs: { icon: "check" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.errors.has("email")
                    ? _c(
                        "span",
                        {
                          staticClass: "icon is-small is-right has-text-danger"
                        },
                        [_c("fa", { attrs: { icon: "exclamation-triangle" } })],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _vm.errors.has("email")
                ? _c(
                    "p",
                    { staticClass: "has-text-left has-text-danger is-size-7" },
                    [_vm._v(" " + _vm._s(_vm.errors.get("email")) + " ")]
                  )
                : _vm._e()
            ]),
            _vm.isLogin || _vm.isReset
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    { staticClass: "control has-icons-left has-icons-right" },
                    [
                      _vm.passwordMeta.content === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password"
                              }
                            ],
                            staticClass: "input",
                            class: {
                              "is-danger": _vm.errors.has("password"),
                              "is-success": _vm.isSuccessful
                            },
                            attrs: {
                              placeholder: _vm.i18n("Password"),
                              autocomplete: "current-password",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.password)
                                ? _vm._i(_vm.password, null) > -1
                                : _vm.password
                            },
                            on: {
                              input: function($event) {
                                return _vm.errors.clear("password")
                              },
                              change: function($event) {
                                var $$a = _vm.password,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.password = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.password = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.password = $$c
                                }
                              }
                            }
                          })
                        : _vm.passwordMeta.content === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password"
                              }
                            ],
                            staticClass: "input",
                            class: {
                              "is-danger": _vm.errors.has("password"),
                              "is-success": _vm.isSuccessful
                            },
                            attrs: {
                              placeholder: _vm.i18n("Password"),
                              autocomplete: "current-password",
                              type: "radio"
                            },
                            domProps: { checked: _vm._q(_vm.password, null) },
                            on: {
                              input: function($event) {
                                return _vm.errors.clear("password")
                              },
                              change: function($event) {
                                _vm.password = null
                              }
                            }
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password"
                              }
                            ],
                            staticClass: "input",
                            class: {
                              "is-danger": _vm.errors.has("password"),
                              "is-success": _vm.isSuccessful
                            },
                            attrs: {
                              placeholder: _vm.i18n("Password"),
                              autocomplete: "current-password",
                              type: _vm.passwordMeta.content
                            },
                            domProps: { value: _vm.password },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.password = $event.target.value
                                },
                                function($event) {
                                  return _vm.errors.clear("password")
                                }
                              ]
                            }
                          }),
                      _c(
                        "span",
                        { staticClass: "icon is-small is-left" },
                        [_c("fa", { attrs: { icon: "lock" } })],
                        1
                      ),
                      _vm.password && !_vm.isSuccessful
                        ? _c("reveal-password", {
                            class: {
                              "is-spaced":
                                _vm.isSuccessful || _vm.errors.has("password")
                            },
                            attrs: { meta: _vm.passwordMeta }
                          })
                        : _vm._e(),
                      _vm.isSuccessful
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "icon is-small is-right has-text-success"
                            },
                            [_c("fa", { attrs: { icon: "check" } })],
                            1
                          )
                        : _vm._e(),
                      _vm.errors.has("password")
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "icon is-small is-right has-text-danger"
                            },
                            [
                              _c("fa", {
                                attrs: { icon: "exclamation-triangle" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._t("password-strength", null, {
                        password: _vm.password,
                        hasPassword: _vm.hasPassword
                      })
                    ],
                    2
                  ),
                  _vm.errors.has("password")
                    ? _c(
                        "p",
                        {
                          staticClass: "has-text-left has-text-danger is-size-7"
                        },
                        [_vm._v(" " + _vm._s(_vm.errors.get("password")) + " ")]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.isReset
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    { staticClass: "control has-icons-left has-icons-right" },
                    [
                      _vm.confirmationMeta.content === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.passwordConfirmation,
                                expression: "passwordConfirmation"
                              }
                            ],
                            staticClass: "input",
                            class: {
                              "is-danger": _vm.errors.has("password"),
                              "is-success": _vm.isSuccessful
                            },
                            attrs: {
                              placeholder: _vm.i18n("Repeat Password"),
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.passwordConfirmation)
                                ? _vm._i(_vm.passwordConfirmation, null) > -1
                                : _vm.passwordConfirmation
                            },
                            on: {
                              input: function($event) {
                                return _vm.errors.clear("password")
                              },
                              change: function($event) {
                                var $$a = _vm.passwordConfirmation,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.passwordConfirmation = $$a.concat([
                                        $$v
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.passwordConfirmation = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.passwordConfirmation = $$c
                                }
                              }
                            }
                          })
                        : _vm.confirmationMeta.content === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.passwordConfirmation,
                                expression: "passwordConfirmation"
                              }
                            ],
                            staticClass: "input",
                            class: {
                              "is-danger": _vm.errors.has("password"),
                              "is-success": _vm.isSuccessful
                            },
                            attrs: {
                              placeholder: _vm.i18n("Repeat Password"),
                              type: "radio"
                            },
                            domProps: {
                              checked: _vm._q(_vm.passwordConfirmation, null)
                            },
                            on: {
                              input: function($event) {
                                return _vm.errors.clear("password")
                              },
                              change: function($event) {
                                _vm.passwordConfirmation = null
                              }
                            }
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.passwordConfirmation,
                                expression: "passwordConfirmation"
                              }
                            ],
                            staticClass: "input",
                            class: {
                              "is-danger": _vm.errors.has("password"),
                              "is-success": _vm.isSuccessful
                            },
                            attrs: {
                              placeholder: _vm.i18n("Repeat Password"),
                              type: _vm.confirmationMeta.content
                            },
                            domProps: { value: _vm.passwordConfirmation },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.passwordConfirmation = $event.target.value
                                },
                                function($event) {
                                  return _vm.errors.clear("password")
                                }
                              ]
                            }
                          }),
                      _c(
                        "span",
                        { staticClass: "icon is-small is-left" },
                        [_c("fa", { attrs: { icon: "lock" } })],
                        1
                      ),
                      _vm.passwordConfirmation && !_vm.isSuccessful
                        ? _c("reveal-password", {
                            class: {
                              "is-spaced":
                                _vm.match ||
                                _vm.isSuccessful ||
                                _vm.errors.has("password")
                            },
                            attrs: { meta: _vm.confirmationMeta }
                          })
                        : _vm._e(),
                      _vm.errors.has("password")
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "icon is-small is-right has-text-danger"
                            },
                            [
                              _c("fa", {
                                attrs: { icon: "exclamation-triangle" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      (_vm.match && !_vm.errors.has("password")) ||
                      _vm.isSuccessful
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "icon is-small is-right has-text-success"
                            },
                            [_c("fa", { attrs: { icon: "check" } })],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.errors.has("password")
                    ? _c(
                        "p",
                        {
                          staticClass: "has-text-left has-text-danger is-size-7"
                        },
                        [_vm._v(" " + _vm._s(_vm.errors.get("password")) + " ")]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.isLogin && !_vm.isWebview
              ? _c("div", { staticClass: "field has-text-white" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("label", { staticClass: "checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.remember,
                            expression: "remember"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.remember)
                            ? _vm._i(_vm.remember, null) > -1
                            : _vm.remember
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.remember,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.remember = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.remember = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.remember = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.i18n("Remember me")) + " ")
                    ])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "field" }, [
              _c(
                "button",
                {
                  staticClass: "button is-primary is-fullwidth",
                  class: { "is-loading": _vm.loading },
                  attrs: { type: "submit" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "icon is-small" },
                    [
                      _c("fa", {
                        attrs: { icon: _vm.isReset ? "lock" : "user" }
                      })
                    ],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(_vm.i18n(_vm.action)))])
                ]
              )
            ])
          ]
        ),
        _vm.isLogin
          ? _c(
              "router-link",
              {
                staticClass: "is-pulled-right has-text-white",
                attrs: { to: { name: "password.email" } }
              },
              [_vm._v(" " + _vm._s(_vm.i18n("Forgot password")) + " ")]
            )
          : _vm._e(),
        _c("div", { staticClass: "is-clearfix" }),
        _vm.isLogin && _vm.allowRegisterEither
          ? _c(
              "div",
              { staticClass: "page-navigation" },
              [
                _vm.allowRegisterAccount
                  ? _c(
                      "router-link",
                      { attrs: { to: { name: "register.account" } } },
                      [_vm._v(" " + _vm._s(_vm.i18n("Register Account")) + " ")]
                    )
                  : _vm._e(),
                _vm.allowRegisterBoth ? _c("span", [_vm._v("•")]) : _vm._e(),
                _vm.allowRegisterCompany
                  ? _c(
                      "router-link",
                      { attrs: { to: { name: "register.company" } } },
                      [_vm._v(" " + _vm._s(_vm.i18n("Register Company")) + " ")]
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "is-clearfix" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }